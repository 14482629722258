import React from 'react'
import { Routes, Route } from "react-router-dom";

import { ComercialRoutes } from './ComercialRoutes';
import { OperacionRoutes } from './OperacionRoutes';
import { FinanzaRoutes } from './FinanzaRoutes';
import { ServicioRoutes } from './ServicioRoutes';
import { SeguridadRoutes } from './SeguridadRoutes';
import { SalaVentaRoutes } from './SalaVentaRoutes';

import { LayoutScreen } from '../componentes/view/menus/LayoutScreen';
import { Header } from '../componentes/ui/Header';
import { Sidebar } from '../componentes/ui/Sidebar';
import { CentroAcopioRoutes } from './CentroAcopioRoutes';

export const LayoutRoutes = () => {  

  return (
    <>
      <Header/>
      <Sidebar/>
      <div className='container-md py-md-4 py-2 '>
        <Routes>
            <Route path="/" element={ <LayoutScreen /> } />
            /* comercial */
            <Route path="comercial/*" element={ <ComercialRoutes/> } />
            /* operaciones */
            <Route path="operacion/*" element={ <OperacionRoutes/> } />            
            /* finanzas */
            <Route path="finanza/*" element={ <FinanzaRoutes/> } />      
            /* servicios */
            <Route path="servicio/*" element={ <ServicioRoutes/> } />  
            /* seguridad */
            <Route path="seguridad/*" element={ <SeguridadRoutes/> } />   
            /* sala de venta */
            <Route path="salaventa/*" element={ <SalaVentaRoutes/> } />   
            /* Centro de Acopio */  
            <Route path="sial/*" element={ <CentroAcopioRoutes/> } />

        </Routes>
      </div>
    </>
  )
}
