import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Asegúrate de haber importado Bootstrap CSS
import { useParams } from 'react-router-dom';

const DashboardOM = () => {
    let { orderId } = useParams(); 
    const [orderData, setOrderData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const datosMetodo = {
                    NombreMetodo: "BuscarInfoOMEspecifico",
                    Datos_Recibidos: `{"FECHAINI":"06/05/2024","FECHAFIN":"06/05/2024","CODOV":"${orderId}"}`
                };
                const datos = {
                    Token: "LJKNFUH2FWEJBAEFFFFFKJ VGDEA003CSFCAQWEUIRRRRRRRRRRRADFYGBIFHVBESRNYHIOPWJROTY4WY",
                    Ambiente: "DESA",
                    Dispositivo: {
                        Marca: "SAMSUNG",
                        Modelo: "S22002B",
                        Version: "Android 18.125",
                        McAdress: null,
                        PowerStatus: "84%",
                        WifiStatus: "Conected to iphone de Mauricio",
                        GPSStatus: "ON",
                        GPS: "321|-485|321"
                    },
                    Usr: {
                        Codigo: "",
                        UserNickName: "pierril"
                    },
                    DatosMetodo: datosMetodo
                };

                const fetchDataUrl = `https://appcorp.parma.com.gt/API/ReceptorDatos?Datos=${encodeURIComponent(JSON.stringify(datos))}`; 
      

                const response = await fetch(fetchDataUrl);
                const data = await response.json();
               // console.log(data) 
               // console.log(data.datos_Generados) 
                const data2 = await JSON.parse(data.datos_Generados)
                console.log(data2);
                setOrderData(data2); 
            } catch (error) {
                console.error('Error fetching order data:', error);
            }
        };

        fetchData();
    }, [orderId]);

//console.log(orderData.datos_Generados); 
//console.log(JSON.parse(orderData.datos_Generados));
//console.log(orderData[0]);


  return (
    <div className="container">
      <header className="p-3 border-bottom">
        {/* Contenido del encabezado */}
      </header>

      {/* Detalles de la orden y otro contenido aquí */}
      <div className="p-3 border-bottom">
        <h5 className="card-title"><b>No de orden: {orderId} </b></h5>
        <div className="card" style={{ width: '100%' }}>
          <div className="card-body">
            <h5 className="card-title">Datos Generales</h5>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <h6 className="card-subtitle mb-2 text-muted">Datos del Cliente</h6>
                  {orderData && orderData[0] && orderData[0].Cliente && (
                                        <p className="card-text">
                                            <b>Nombre:</b> {orderData[0].Cliente.Nombre} <br/>
                                            <b>Contacto Creditos:</b> {orderData[0].Cliente.ContactoCreditos}<br/>
                                            <b>Contacto Ventas:</b> {orderData[0].Cliente.ContactoVentas} <br/>
                                        </p>
                                    )}
                </div>
                <div className="col-md-6">
                  <h6 className="card-subtitle mb-2 text-muted">Datos de Envío</h6>
                  {orderData && orderData[0] && orderData[0].Cliente  &&  (
                                        <p className="card-text">
                                            <b>Dirección Fiscal:</b> {orderData[0].Cliente.DireccionRS} <br/>
                                            <b>Direccion Entrega:</b> {orderData[0].Cliente.DireccionEN}<br/>
                                         
                                        </p>
                                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
       
       {
/*
      
  <div className="row text-center">
 <div className="col">
    <div className="card shadow-sm border-success">
      <div className="card-header bg-success text-white">
        Pedido
      </div>
      <div className="card-body ">
        <div className="row">
          321654
          Activo
          05/08/2023
        </div>
      </div>
    </div>
  </div>
  <div className="col">
    <div className="card shadow-sm border-info">
      <div className="card-header bg-info text-white">
        Orden de Venta
      </div>
      <div className="card-body ">
        <div className="row ">
          987654
          Activo
          05/08/2023
        </div>
      </div>
    </div>
  </div>
  
  <div className="col">
    <div className="card shadow-sm border-danger">
      <div className="card-header bg-danger text-white">
        Picking
      </div>
      <div className="card-body ">
        <div className="row ">
          847
          En Proceso
          23/08/2023
        </div>
      </div>
    </div>
  </div>
  <div className="col">
    <div className="card shadow-sm border-primary">
      <div className="card-header bg-primary text-white">
        Entrega
      </div>
      <div className="card-body ">
        <div className="row ">
          ######
          Pendiente
          24/08/2023
        </div>
      </div>
    </div>
  </div>
  
  <div className="col">
    <div className="card shadow-sm border-success">
      <div className="card-header bg-success text-white">
        Factura
      </div>
      <div className="card-body ">
        <div className="row ">
          ######
          Pendiente
          ##/##/####
        </div>
      </div>
    </div>
  </div>
 
  <div className="col"></div>
  <div className="col"></div>
</div>  */} 
<div className="row text-center">
<div className="container">
  <div className="row">
    {orderData && orderData[0] && orderData[0].Documentos.map((documento, index) => (
      <div className="col" key={index}>
        <div className={`card shadow-sm border-${documento.Color}`}>
          <div className={`card-header bg-${documento.Color} text-black`}>
            {documento.Documento}
          </div>
          <div className="card-body">
            <div className="row">
              <p> <b>{documento.Referencia}</b></p>
              <p>{documento.Comentarios}</p>
              <p>{documento.FechaHora}</p>
            </div>
          </div>
        </div>
      </div>
    ))}
  </div>
</div>


</div>
<br />
<br />
<div className="row text-center">
  { /*<div className="col-md-2">
    <div className="card shadow-sm border-warning">
      <div className="card-header bg-warning text-white">
        Orden de Compra
      </div>
      <div className="card-body">
        <div className="row">
          FEJBB3216
          03/08/2023
        </div>
      </div>
    </div>
  </div>*/}
  <div className="col-md-1"></div>
  <div className="col-md-9">
    <div className="card shadow-sm border-default">
      <div className="card-header bg-default">
        Notificación
      </div>
      <div className="card-body text-start">
        <div className="row">
          <div className="col-md-10">Proximamente.....</div>
          <button type="button" className="col-md-2 btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#exampleModal">----</button>
        </div>
        {/* Repetir para otras notificaciones */}
        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Correo Enviado</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <p>Correo Ejemplo.</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<hr />
<div className="row">
      { /*<div className="col-md-6">
        <div className="card" style={{ width: '100%' }}>
          <div className="card-body">
            <h5 className="card-title">Mercancía/Mercadería</h5>
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Unidades</th>
                  <th>Descripción</th>
                  <th>Precio Unitario</th>
                  <th>Total Unitario</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Mozza fresca bola tarro (+-13 G) 400 g.</td>
                  <td>Q. 57.75</td>
                  <td>Q. 57.75</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Helado Wafle grande vainilla</td>
                  <td>Q. 12</td>
                  <td>Q. 36</td>
                </tr>
                <tr>
                  <td colSpan="3" className="table-active text-end">TOTAL</td>
                  <td>Q. 93.75</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
</div> */} 
<div className="col-md-6">
  <div className="card" style={{ width: '100%' }}>
    <div className="card-body">
      <h5 className="card-title">Mercancía/Mercadería</h5>
      <table className="table table-striped table-hover">
        <thead>
          <tr> 
            <th>Codigo</th>
            <th>Descripción</th>
            <th>Precio Unitario</th> 
            <th>Cnt. Cot.</th> 
            <th>Cnt. Pedido</th> 
            <th>Cnt. OV.</th> 
            <th>Cnt. Pk.</th> 
            <th>Cnt. Fact.</th> 
           

          { // <th>Total </th>
          }
          </tr>
        </thead>
        <tbody>
          {orderData && orderData[0] && orderData[0].Productos.map((producto, index) => (
            <tr key={index}>
              <td>{producto.Codigo}</td>
              <td>{producto.Descripcion}</td>
              <td>Q. {parseFloat(producto.Precio).toFixed(2)}</td> 
              <td>{producto.CantidadCoti}</td>
              <td>{producto.CantidadPed}</td>
              <td>{producto.CantidadOV}</td>
              <td>{producto.CantidadPik}</td>
              <td>{producto.CantidadFactura}</td> 
              

             {
             // <td>Q. {(parseFloat(producto.CantidadOV) * parseFloat(producto.Precio)).toFixed(2)}</td>
             } 
            </tr>
          ))}
        { /* <tr>
            <td colSpan="3" className="table-active text-end">TOTAL</td>
            {orderData && orderData[0] && orderData[0].Productos && (
  <td>Q. {orderData[0].Productos.reduce((total, producto) => total + (parseFloat(producto.CantidadOV) * parseFloat(producto.Precio)), 0).toFixed(2)}</td>
)}

          </tr> */
          }
        </tbody>
      </table>
    </div>
  </div>
</div>


     {/* <div className="col-md-6">
        <div className="card" style={{ width: '100%' }}>
          <div className="card-body">
            <h5 className="card-title">Bitácora</h5>
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Paso</th>
                  <th>Descripción</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Se recibe la información para generar la Orden</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Se validan datos primarios</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Se verifican datos para envío de correo</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Se envió correo a "luis.e.pierri.sanchez@gmail.com"</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>Se registra apertura de link enviado</td>
                </tr>
                <tr>
                  <td colSpan="2" className="table-active">Tiempo del proceso:</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
</div> */}   
<div className="col-md-6">
  <div className="card" style={{ width: '100%' }}>
    <div className="card-body">
      <h5 className="card-title">Bitácora</h5>
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th>Paso</th>
            <th>Descripción</th> 
            <th>Fecha</th> 

          </tr>
        </thead>
        <tbody>
          {orderData && orderData[0] && orderData[0].Bitacora.map((documento, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{documento.Descripcion}</td> 
              <td>{documento.Fecha}</td> 

            </tr>
          ))}
          <tr>
            <td colSpan="2" className="table-active">Tiempo del proceso:</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>


    </div>
    <hr />
      <div className="row row-cols-1 row-cols-md-2 mb-2 text-center">
        <div className="col">
          <div className="card mb-12 rounded-3 shadow-sm border-success">
            <div className="card-header bg-success text-white">
              Pago con Tarjeta
            </div>
            <div className="card-body">
              <div className="row">
                {/* Espacio intencionalmente en blanco para demostración */}
                <br /><br /><br /><br /><br /><br /><br /><br />
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="col-md-6">
            <a href="https://report.feel.com.gt/ingfacereport/ingfacereport_documento?uuid=8C19F9E0-F190-4E24-A53B-8BFD8769B48A" className="btn btn-info" target="_blank" rel="noopener noreferrer">Ver Factura</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardOM;
