import React from 'react'
import { Routes, Route } from "react-router-dom";

import { FinanzaScreen } from '../componentes/view/finanzas/FinanzaScreen';

export const FinanzaRoutes = () => {

  return (
    <Routes>            
      <Route path="/" element={ <FinanzaScreen /> } />
    </Routes>
  )
}
